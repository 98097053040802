/*google fonts*/
/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;1,100;1,500&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;600;800&display=swap');

/********** Variables **********/
:root {
  /*Colors*/
  --first-color: #dfa2a2;
  --title-color: #722b2b;
  --text-color: #000000;
  --body-color: #ffffff;
  --container-color: #ffffffaf;
  /*Fonts*/
  --body-font: 'Dosis', arial, sans-serif;
  --h1-font-size: 2.5rem;
  --h2-font-size: 2rem;
  --h3-font-size: 1.5rem;
  --normal-font-size: 1rem;
  --small-font-size: .875rem;
  --smaller-font-size: .75rem;
  /*Font Weight*/
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  /*Box Shadow*/
  --shadow: 0 5px 20px 0px rgba(0, 0, 0, .1);
/*Border Radius*/
--border-radius: 20px;
}

/*Responsive*/

@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.75rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
  
}

/********** Base **********/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--body-font);
}
html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  
  font-size: var(--normal-font-size);
  
}
body {
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
}
h1,h2,h3,h4,h5,h6 {
  color: var(--title-color);
  font-weight: var(--font-bold);
  line-height: 1.2;
}
ul {
  list-style: none;
}

a {
  text-decoration: none;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*Reusable CSS Classes*/

.container {
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.section {
  padding-top: 7rem 0;
  padding-bottom: 2rem 0;
}
.section-title {
  font-size: var(--h1-font-size);
  margin-left: .875rem;
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;

}
.section-title::before {
  content: '';
  background: url(./assets/dots-bg.svg);
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
  width: 2.25rem;
  height: 2.25rem;
}
.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .1);
  border: 1px solid transparent;
  color: var(--title-color);
  display: inline-block;
  font-weight: var(--font-bold);
  background-color: rgba(255, 192, 203, 0.685);
  transition: all 0.3s ease-in-out;
  
}
@keyframes button-push {
  
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.btn:hover {
  animation: button-push 0.3s linear 1;
}
