.about__container{
    margin-top: 20%;
    grid-template-columns: 4fr 8fr;
    column-gap: 1.875rem;
    font-size: 20px;
    text-align: center;
}

.about__img{
    border-radius: 100%;
    width: 80%;
    height: auto;
    margin-left: 5%;

    
}

.img_cover{
   
    margin-top: 5%;
    display: flex;
    align-items: center;
    
}

.section__title-about{
    margin-bottom: 5%;
    text-align: center;
    
}

.btn{
    margin-left: auto;
    margin-right: auto;
}

.btn_cover{
    margin-top: 5%;
    display: flex;
    
}



.about__container2{
    margin-top: 5%;
    grid-template-columns: 6fr 6fr;
    column-gap: 1.875rem;
}


.ds-skills-section{ 
    padding-bottom: 70px; 
    background-color: white;
    box-shadow: var(--shadow);
    border-radius: 40px;
    margin-top: 10%;
    margin-bottom: 20%;
   
    grid-template-columns: 6fr 6fr;
    column-gap: 1.875rem;

   
}

.ds-skills-list li{ display: inline-block; color: #373737; font-size: 20px; padding:10px 20px; background-color: #FFF; border: 1px solid #D7D7D7;
  border-radius: 27px; margin-right: 15px; margin-bottom: 15px;  font-weight: 500; }

.ds-heading {
    text-align: center;
    margin-top: 3%;
    margin-bottom: 5%;
}

.ds-skills-list{
    margin-left: 10%;
   
}
  
  