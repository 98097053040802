.home{
    position:relative;
    min-height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro{
    max-width: 600px;
    text-align: center;
}
.home__img{
    margin-bottom: 1.5rem;
    
}

.home__name{
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    margin-bottom: 0.5rem;
    text-align: center;
}

.home__socials{
    display: flex;
    column-gap: 6rem;
    margin: 1rem 0;
    
}
.home__social-link{
    color: var(--title-color);
    font-size: 40px;
    transition: var(--transition);
}

.home__social-link:hover{
    color: hsl(347, 100%, 68%);
}

.scroll-down{
    position: absolute;
    bottom: 2.5rem;
    
    width: 100%
}
 .home__scroll-name{
   font-size: var(--small-font-size); 
 }

 .mouse{
    border: 2px solid var(--title-color);
    display: block;
    height: 1.6rem;
    width:1.25rem;
    margin: auto;
    margin-top: .75;
    border-radius: 1rem;
    position: relative; 
 }

 @keyframes ani-mouse{
     0%{
         top: 29%;
     }
     15%{
       top: 50%   
     }
     50%{
         top: 50%;
     }
     100%{
         top: 29%;
     }
 }

 .wheel{
    background-color: var(--title-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: ani-mouse 2s linear infinite;
 }

 .shapes{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;

 }
 
 .shape{
    position: absolute;
 }

 .s1{
    left: 2%;
    top: 10%;
 }

.s2
{
    left: 70%;
    top: 60%;
}

.s3
{
    left: 18%;
    top: 90%;
}
.s4
{
    left: 30%;
    top: 40%;
}
.s5
{
    left: 5%;
    top: 30%;
}
.s6
{
    left: 2%;
    top: 10%;
}
.s7
{
    left: 44%;
    top: 10%;
}
.s8
{
    left: 36%;
    bottom: 10%;
}
.s9
{
    right: 25%;
    top: 20%;
}
.s10
{
    right: 10%;
    top: 35%;
}
.s11
{
    right: 15%;
    bottom: 15%;
}
.s12
{
    left: 5%;
    top: 70%;
}

.s13
{
    right: 10%;
    top: 5%;
}


